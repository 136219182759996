import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Header from '../components/header';
import ConnectForm from '../components/connectForm';

const Connect = () => (
  <Layout>
    <Seo title="Cheeky Unts - Connect" />
    <Header />
    <ConnectForm />
  </Layout>
);

export default Connect;
