import React, { useState, useEffect } from 'react';

import { TextField, Snackbar, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import Button from './button';

import './connectForm.scss';

const Connect = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const {
    control, handleSubmit, setValue, reset,
  } = useForm({
    defaultValues: {
      address: '',
    },
  });

  useEffect(() => {
    if (localStorage.getItem('cheekyWalletAddress')) {
      setValue('address', localStorage.getItem('cheekyWalletAddress'));
    }
  }, []);

  const onSubmit = ({ address }) => {
    localStorage.setItem('cheekyWalletAddress', address);
    setSnackbarOpen(true);
  };

  const handleRemove = () => {
    reset();
    localStorage.removeItem('cheekyWalletAddress');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className="connect-form">
        <h1>Connect Your Cardano Wallet</h1>
        <p>This is just a beta.</p>
        <form className="connect-form__input" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                variant="outlined"
                placeholder="addr1xxx"
                required
                fullWidth
                onFocus={(event) => {
                  event.target.select();
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
              />
            )}
          />
          <Button label="Save Address" onButtonClick={handleSubmit} isSubmit />
        </form>
        <button className="connect-form__forget" type="button" onClick={handleRemove}>Forget Address</button>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        bgcolor="red"
      >
        <Alert severity="success">
          Saved
        </Alert>
      </Snackbar>
    </>
  );
};

export default Connect;
